





















import { Vue, Component } from "vue-property-decorator";
import DataQualityTable from "@/components/dataQuality/dataQualityTable/DataQualityTable.vue";
import DataQualityIndicatorLegend from "@/components/dataQuality/DataQualityIndicatorLegend.vue";
import DataQualityModule from "@/store/clients/DataQuality.module";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import VesselsModule from "@/store/clients/Vessels.module";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataQualityTable,
    DataQualityIndicatorLegend,
  },
})
export default class FleetOverview extends Vue {
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshFleetOverview()]);
  }

  get fleetOverview(): any {
    var fleetOverview = DataQuality.fleetOverview.map(item => {
      const vessel = Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId);
      return {
        vesselName: {
          value: vessel ? vessel.name : "Unknown Vessel",
        },
        overallStatus: {
          value: item.overallStatus,
        },
        dataAvailability: {
          value: item.dataAvailability,
        },
        dataOutliers: {
          value: item.dataOutliers,
        },
        speedLog: {
          value: 0,
        },
      };
    });
    return fleetOverview;
  }

  get tableLoading(): boolean {
    return DataQuality.loadingState;
  }

  links: (string | null)[] = [null, "/DataQuality/DataAvailability", "/DataQuality/DataOutliers", null];

  headers: DataQualityHeader[] = [
    {
      text: "Vessel",
      type: "string",
      value: "vesselName",
    },
    {
      text: "Data Availability",
      type: "dataIndicator",
      value: "dataAvailability",
      tooltip:
        "This column displays the availability of critical data tags for performance analyses. The vessel’s status is determined by the most urgent status of the data tags. In other words if just one tag is “Not Ok” and all other tags are available, the vessel’s status will be “Not ok”. The status of each tag is determined by the configurations made in the Data Availability Dashboard.",
    },
    {
      text: "Data Outliers",
      type: "dataIndicator",
      value: "dataOutliers",
      tooltip: "Indicates the presence of outliers in the data.",
    },
    {
      text: "Speed Log",
      type: "dataIndicator",
      value: "speedLog",
      tooltip:
        "This column displays the status of the Speed Log sensor. The vessel's status is determined by the outlier score and quality score displayed in the Speed Log Dashboard. The overall status of the Speed Log is determined by the most urgent status observed. If the quality score is green but the Outlier score is “Not Ok” the Speed Log column for the vessel will show “Not Ok”.",
    },
  ];
}
